import { inject, Injectable, signal } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { MenuItem, NaviApiService } from '@/new-navi/navi.api.service';

@Injectable({
	providedIn: 'root',
})
export class NaviService {
	readonly menuItems = signal<MenuItem[]>([]);

	private readonly naviApiService = inject(NaviApiService);

	getMenuItems(): void {
		this.naviApiService
			.getMenuWWW()
			.pipe(
				catchError((error) => {
					throw error;
				}),
			)
			.subscribe((results) => this.menuItems.set(results));
	}
}
