// counter.effects.ts
import { OfferApiService } from '@/_api/offer.api.service';
import {
	Accommodation,
	CountRoomType,
	DescriptionTemplate,
	MatProduct,
	MatPromotion,
	OptionalTrip,
	PriceSettingsResult,
	SellTerm,
	SellTransport,
	SellTransportStatistics,
} from '@/_store/current-offer/current-offer.types';
import { Result } from '@/common/data.service';
import { OfferDropdownItemMode } from '@/offer/new-offer-configurator/offer-dropdown-item/offer-dropdown-item.component';
import { Descriptions, LocationDescriptions } from '@/offer/offer.model';
import { isPlatformServer } from '@angular/common';
import { EnvironmentInjector, Inject, Injectable, PLATFORM_ID, inject, runInInjectionContext } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as currentOfferActions from './current-offer.actions';
import { filterPromotionsByDateRange, findBestTerm, findParamsTerm, findPromo } from './current-offer.utils';
import { FetchOfferStatistics } from '@/offer/fetch-offer-statistics/fetch-offer-statistics.service';

@Injectable()
export class CurrentOfferEffects {
	constructor(
		private readonly actions$: Actions,
		private readonly offerApiService: OfferApiService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router,
		@Inject(PLATFORM_ID) private readonly platformId: string,
		private readonly environmentInjector: EnvironmentInjector,
	) {}

	fetchMatProducts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(currentOfferActions.fetchMatProduct),
			switchMap(({ id }) =>
				this.offerApiService.getMatProduct(id).pipe(
					map((product: MatProduct) => currentOfferActions.fetchMatProductSuccess({ product })),
					catchError((error) => of(currentOfferActions.fetchMatProductError({ error }))),
				),
			),
		),
	);

	fetchMatProductError$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(currentOfferActions.fetchMatProductError),
				tap(() => {
					if (isPlatformServer(this.platformId)) {
						return;
					}

					this.router.navigate(['/404'], {
						skipLocationChange: true,
					});
				}),
			),
		{ dispatch: false },
	);

	fetchOptionalTrip$ = createEffect(() =>
		this.actions$.pipe(
			ofType(currentOfferActions.fetchOptionalTrip),
			switchMap(({ locationIds }) =>
				this.offerApiService.getOptionalTrip(locationIds).pipe(
					map((result: Result<OptionalTrip>) =>
						currentOfferActions.fetchOptionalTripSuccess({
							optionalTrip: result.results,
						}),
					),
					catchError((error) =>
						of(
							currentOfferActions.fetchOptionalTripError({
								error,
							}),
						),
					),
				),
			),
		),
	);

	fetchLocations$ = createEffect(() =>
		this.actions$.pipe(
			ofType(currentOfferActions.fetchLocations),
			switchMap(({ ids }) =>
				this.offerApiService.getLocations(ids).pipe(
					map((locations: LocationDescriptions[]) =>
						currentOfferActions.fetchLocationsSuccess({
							locations,
						}),
					),
					catchError((error) =>
						of(
							currentOfferActions.fetchLocationsError({
								error,
							}),
						),
					),
				),
			),
		),
	);

	fetchDescription$ = createEffect(() =>
		this.actions$.pipe(
			ofType(currentOfferActions.fetchDescription),
			switchMap(() =>
				this.offerApiService.getDescription().pipe(
					map((result: Result<Descriptions>) =>
						currentOfferActions.fetchDescriptionSuccess({
							descriptions: result.results,
						}),
					),
					catchError((error) =>
						of(
							currentOfferActions.fetchDescriptionError({
								error,
							}),
						),
					),
				),
			),
		),
	);

	fetchDescriptionTemplate$ = createEffect(() =>
		this.actions$.pipe(
			ofType(currentOfferActions.fetchDescriptionTemplate),
			switchMap(() =>
				this.offerApiService.getDescriptionTemplate().pipe(
					map((result: Result<DescriptionTemplate>) =>
						currentOfferActions.fetchDescriptionTemplateSuccess({
							descriptionTemplates: result.results,
						}),
					),
					catchError((error) =>
						of(
							currentOfferActions.fetchDescriptionTemplateError({
								error,
							}),
						),
					),
				),
			),
		),
	);

	fetchSellTransport$ = createEffect(() =>
		this.actions$.pipe(
			ofType(currentOfferActions.fetchSellTransport),
			switchMap(({ id }) =>
				this.offerApiService.getOfferSellTransport(id).pipe(
					map((result: Array<SellTransport>) =>
						currentOfferActions.fetchSellTransportSuccess({
							sellTransport: result,
						}),
					),
					catchError((error) =>
						of(
							currentOfferActions.fetchSellTransportError({
								error,
							}),
						),
					),
				),
			),
		),
	);

	fetchAccommodation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(currentOfferActions.fetchAccommodation),
			switchMap(({ id }) =>
				this.offerApiService.getAccommodation(id).pipe(
					map((result: Array<Accommodation>) =>
						currentOfferActions.fetchAccommodationSuccess({
							accommodation: result,
						}),
					),
					catchError((error) =>
						of(
							currentOfferActions.fetchAccommodationError({
								error,
							}),
						),
					),
				),
			),
		),
	);

	fetchSellTerm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(currentOfferActions.fetchSellTerm),
			switchMap(({ params }) =>
				this.offerApiService.getOfferSellTerm(params).pipe(
					map((result: Result<SellTerm>) =>
						currentOfferActions.fetchSellTermSuccess({
							sellTerms: result.results,
						}),
					),
					catchError((error) =>
						of(
							currentOfferActions.fetchSellTermError({
								error,
							}),
						),
					),
				),
			),
		),
	);

	fetchSellTermSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(currentOfferActions.fetchSellTermSuccess),
			withLatestFrom(this.activatedRoute.queryParams),
			switchMap(([{ sellTerms }, queryParams]) => {
				const paramsDateRange = findParamsTerm(sellTerms, queryParams);
				const term = !!paramsDateRange ? paramsDateRange : findBestTerm(sellTerms);
				return of(
					currentOfferActions.updateFilterTerm({
						dateRange: {
							upper: term.dateRange.upper,
							lower: term.dateRange.lower,
							termPrice: term.termPrice,
							termId: term.id,
							transportFrom: term.transportFrom,
							transportTo: term.transportTo,
							nights: term.nights,
							omnibusPrice: term.omnibusPrice ?? null,
						},
					}),
				);
			}),
		),
	);

	fetchPriceSettings$ = createEffect(() =>
		this.actions$.pipe(
			ofType(currentOfferActions.fetchPriceSettings),
			switchMap(({ id }) =>
				this.offerApiService.getOfferPriceSettings(id).pipe(
					map((result: PriceSettingsResult) =>
						currentOfferActions.fetchPriceSettingsSuccess({
							priceSettings: result.calculatedPriceSetting,
						}),
					),
					catchError((error) =>
						of(
							currentOfferActions.fetchPriceSettingsError({
								error,
							}),
						),
					),
				),
			),
		),
	);

	fetchPromotions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(currentOfferActions.fetchPromotions),
			switchMap(({ params: params, dateRange: dateRange }) =>
				this.offerApiService.getPromotions(params).pipe(
					map((result: Result<MatPromotion>) => {
						return currentOfferActions.fetchPromotionsSuccess({
							promotions: filterPromotionsByDateRange(result.results, dateRange),
							allPromotions: result.results,
						});
					}),
					catchError((error) =>
						of(
							currentOfferActions.fetchPromotionsError({
								error,
							}),
						),
					),
				),
			),
		),
	);

	fetchPromotionsSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(currentOfferActions.fetchPromotionsSuccess),
			withLatestFrom(this.activatedRoute.queryParams),
			map(([value, queryParams]) =>
				currentOfferActions.updateFilterPromotion({
					promotion: value?.promotions ? findPromo(value.promotions, queryParams) : null,
				}),
			),
		),
	);

	fetchCountRoomType$ = createEffect(() =>
		this.actions$.pipe(
			ofType(currentOfferActions.fetchCountRoomTypes),
			switchMap(({ termId, productId }) =>
				this.offerApiService.getCountRoomTypes(productId, termId).pipe(
					map((roomTypesCount: CountRoomType) =>
						currentOfferActions.fetchCountRoomTypesSuccess({ roomTypesCount }),
					),
					catchError((error) =>
						of(
							currentOfferActions.fetchCountRoomTypesError({
								error,
							}),
						),
					),
				),
			),
		),
	);

	fetchSellTransportStatistics$ = createEffect(() =>
		this.actions$.pipe(
			ofType(currentOfferActions.fetchSellTransportStatistics),
			switchMap(({ termId }) =>
				this.offerApiService.getSellTransportStatistics(termId).pipe(
					map((sellTransportStatistics: SellTransportStatistics) =>
						currentOfferActions.fetchSellTransportStatisticsSuccess({ sellTransportStatistics }),
					),
					catchError((error) =>
						of(
							currentOfferActions.fetchSellTransportStatisticsError({
								error,
							}),
						),
					),
				),
			),
		),
	);

	clearFilterValue$ = createEffect(() =>
		this.actions$.pipe(
			ofType(currentOfferActions.clearFilterValue),
			map(({ option }) => {
				switch (option) {
					case OfferDropdownItemMode.MAT_TERM:
						return currentOfferActions.updateFilterTerm(null);
					case OfferDropdownItemMode.BOARDS:
						return currentOfferActions.updateFilterBoard(null);
					case OfferDropdownItemMode.DEPARTURE:
						return currentOfferActions.updateFilterStop(null);
					case OfferDropdownItemMode.PARTICIPANTS:
						return currentOfferActions.updateFilterParticipants(null);
					case OfferDropdownItemMode.ROOM_TYPES:
						return currentOfferActions.updateFilterRoomType(null);
					case OfferDropdownItemMode.PROMOTIONS:
						return currentOfferActions.updateFilterPromotion(null);
					default:
						const value: never = option;
						throw new Error(`There is no option like ${value}`);
				}
			}),
		),
	);

	updateFilterTerm$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(currentOfferActions.updateFilterTerm),
				filter((value) => !!value),
				withLatestFrom(this.activatedRoute.queryParams),
				tap(([{ dateRange }, queryParams]) => {
					const navigationExtras: NavigationExtras = {
						queryParams: {
							...queryParams,
							date_range: dateRange ? `${dateRange.lower},${dateRange.upper}` : '',
							term_id: dateRange.termId,
						},
						replaceUrl: true,
					};
					this.router.navigate([], navigationExtras);
				}),
			),
		{ dispatch: false },
	);

	updateFilterPromotion$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(currentOfferActions.updateFilterPromotion),
				withLatestFrom(this.activatedRoute.queryParams),
				tap(([{ promotion }, queryParams]) => {
					const navigationExtras: NavigationExtras = {
						queryParams: {
							...queryParams,
							promotion: promotion?.id,
						},
						replaceUrl: true,
					};
					this.router.navigate([], navigationExtras);
				}),
			),
		{ dispatch: false },
	);

	fetchDataOnUpdateFilterTerm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(currentOfferActions.updateFilterTerm),
			filter((value) => !!value),
			tap(({ dateRange }) => {
				runInInjectionContext(this.environmentInjector, () => {
					inject(FetchOfferStatistics).getNewStatistics(dateRange.termId);
				});
			}),
			switchMap(({ dateRange }) => {
				return of(
					currentOfferActions.fetchPriceSettings({
						id: dateRange.termId,
					}),
					currentOfferActions.fetchSellTransport({
						id: dateRange.termId,
					}),
					currentOfferActions.fetchAccommodation({
						id: dateRange.termId,
					}),
				);
			}),
		),
	);

	updateParticipantsFilterValue$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(currentOfferActions.updateFilterParticipants),
				withLatestFrom(this.activatedRoute.queryParams),
				tap(([{ participants }, queryParams]) => {
					const params: { children?: number; adults?: number } = {};
					if (typeof participants?.adults === 'number') {
						params.adults = participants.adults;
					}
					if (typeof participants?.children === 'number') {
						params.children = participants.children;
					}
					const navigationExtras: NavigationExtras = {
						queryParams: { ...queryParams, ...params },
						replaceUrl: true,
					};
					this.router.navigate([], navigationExtras);
				}),
			),
		{ dispatch: false },
	);

	updateRoomTypeFilterValue$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(currentOfferActions.updateFilterRoomType),
				withLatestFrom(this.activatedRoute.queryParams),
				tap(([{ roomType }, queryParams]) => {
					const navigationExtras: NavigationExtras = {
						queryParams: {
							...queryParams,
							room_type: roomType ? roomType.id : '',
						},
						replaceUrl: true,
					};
					this.router.navigate([], navigationExtras);
				}),
			),
		{ dispatch: false },
	);

	updateStopFilterValue$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(currentOfferActions.updateFilterStop),
				withLatestFrom(this.activatedRoute.queryParams),
				tap(([{ transport }, queryParams]) => {
					const params: { own_transport?: boolean; stops?: number } = {};
					if (transport?.ownTransport === true) {
						params.own_transport = true;
						delete params.stops;
					} else if (transport?.stop) {
						params.stops = transport.stop.id;
						delete params.own_transport;
					}
					const navigationExtras: NavigationExtras = {
						queryParams: { ...queryParams, ...params },
						replaceUrl: true,
					};
					this.router.navigate([], navigationExtras);
				}),
			),
		{ dispatch: false },
	);

	updateBoardFilterValue$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(currentOfferActions.updateFilterBoard),
				withLatestFrom(this.activatedRoute.queryParams),
				tap(([{ board }, queryParams]) => {
					const navigationExtras: NavigationExtras = {
						queryParams: {
							...queryParams,
							boards: board ? board?.id : '',
						},
						replaceUrl: true,
					};
					this.router.navigate([], navigationExtras);
				}),
			),
		{ dispatch: false },
	);
}
