import { CurrentOfferFacade } from '@/_store/current-offer/current-offer.facade';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, inject, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter, Subject, take, takeUntil } from 'rxjs';
import { GeneralDataFacade } from './_store/general/general.facade';
import { AuthBagService } from './auth/auth-bag.service';
import { DataService } from './common/data.service';
import { LocalStorageService } from './common/localstorage.service';
import { UpdateService } from './common/update.service';
import { EnvironmentService } from './services/environment/environment.service';
import { GoogleToolsUrl } from './services/google-analitics/google-analytics.enum';
import { GoogleAnaliticsService } from './services/google-analitics/google-analytics.service';
import { UtmParamsService } from './services/utm-params/utm-params.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	providers: [UpdateService],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
	env = this.environmentService.environment;
	googleTagManagerUrl = `${GoogleToolsUrl.MAIN_URL}${this.environmentService.environment?.googleAnalyticsToken}`;

	loading = signal(true);

	private readonly destroy$ = new Subject<void>();
	private readonly currentOfferFacade = inject(CurrentOfferFacade);
	private readonly isLoggedIn$ = inject(GeneralDataFacade).isLoggedIn$;

	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		@Inject(PLATFORM_ID) private readonly platformId: string,
		private readonly bagDataService: DataService,
		private readonly localStorageService: LocalStorageService,
		private readonly router: Router,
		private readonly generalDataFacade: GeneralDataFacade,
		private readonly deviceDetectorService: DeviceDetectorService,
		private readonly authBagService: AuthBagService,
		private readonly googleAnalyticsService: GoogleAnaliticsService,
		private readonly environmentService: EnvironmentService,
		private readonly utmParamsService: UtmParamsService,
	) {
		if (isPlatformServer(this.platformId)) {
			return;
		}
		this.authBagService.tokenRefresh(this.bagDataService);
	}

	ngOnInit(): void {
		if (isPlatformServer(this.platformId)) {
			return;
		}

		this.isLoggedIn$
			.pipe(
				take(1),
				filter((isLoggedIn) => !isLoggedIn),
			)
			.subscribe(() => this.utmParamsService.saveUTMParams());

		this.initializeServices();

		this.authBagService.loggedUserId = this.getDataFromLocalStorage('loggedUserId', null);

		if (navigator && navigator.userAgent) {
			this.generalDataFacade.updatePlatform(this.deviceDetectorService.isMobile() ? 'mobile' : 'desktop');
		}
	}

	ngAfterViewInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.loading.set(false);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	handleRouteEvents(): void {
		this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const pagePath = event.urlAfterRedirects;

				this.googleAnalyticsService.resetDataLayer();

				this.googleAnalyticsService.trackEvent('page_view', {
					page_title:
						'Biuro podróży Poznań, wakacje autokarem oraz samolotem, wycieczki zagraniczne, oferty first minute.',
					page_path: pagePath,
					page_location: this.document.location.href,
				});
			}
		});
	}

	private getDataFromLocalStorage<T, U>(key: string, onFailValue: T): T | U {
		try {
			return JSON.parse(this.localStorageService.getItem(key));
		} catch {
			return onFailValue;
		}
	}

	private initializeServices(): void {
		if (this.environmentService.environment.production) {
			this.googleAnalyticsService.initialize();
			this.handleRouteEvents();
		}

		this.googleAnalyticsService.loadPixelScript(); // we have dev and prod version
	}
}
