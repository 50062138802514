import { EnvironmentService } from '@/services/environment/environment.service';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Multimedia } from '@/_store/offers/offers.types';
import { Result } from '@/common/data.service';

export interface MenuItem {
	id: number;
	name: string;
	isActive: boolean;
	link: string;
	order: number;
	childMenus: MenuItem[];
	anchor: string;
	multimedia: Multimedia;
	textOverListing: string;
	textUnderListing: string;
}

@Injectable({
	providedIn: 'root',
})
export class NaviApiService {
	private readonly environmentService = inject(EnvironmentService);
	private readonly api: string = this.environmentService.environment?.apiUrl;
	private readonly httpClient = inject(HttpClient);

	getMenuWWW(): Observable<MenuItem[]> {
		return this.httpClient.get<Result<MenuItem>>(`${this.api}www/menu_www/`).pipe(map((res) => res?.results));
	}
}
