import { ChangeDetectionStrategy, Component, computed, inject, OnInit } from '@angular/core';
import { DesktopComponent } from '@/new-navi/navi/desktop/desktop.component';
import { AuthBagService } from '@/auth/auth-bag.service';
import { MobileComponent } from '@/new-navi/navi/mobile/mobile.component';
import { GeneralDataFacade } from '@/_store/general/general.facade';
import { AsyncPipe } from '@angular/common';
import { Router } from '@angular/router';
import { OffersFacade } from '@/_store/offers/offers.facade';
import { NaviService } from '@/new-navi/navi.service';
import { MenuItem } from '@/new-navi/navi.api.service';
import { normalizeStringToUrl } from '@/_utils/urls/normalize-string-to-url';

@Component({
	selector: 'app-navi',
	standalone: true,
	templateUrl: './navi.component.html',
	styleUrl: './navi.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [DesktopComponent, MobileComponent, AsyncPipe],
})
export class NaviComponent implements OnInit {
	readonly authBagService = inject(AuthBagService);
	readonly naviService = inject(NaviService);
	readonly isMobile$ = inject(GeneralDataFacade).isMobile$;
	readonly router = inject(Router);
	readonly offersFacade = inject(OffersFacade);

	readonly menuItems = this.naviService.menuItems;
	readonly activeMenuItems = computed(() => this.filterActiveManuItems(this.menuItems()));
	readonly homepageRoute = '/';

	ngOnInit(): void {
		this.naviService.getMenuItems();
	}

	logout(): void {
		this.authBagService.unauth();
	}

	routerSideActions(event: MouseEvent): void {
		const isCtrlPressed = event.ctrlKey || event.metaKey;
		const urlWithoutParams = this.router.url.split('?')[0];

		if (!isCtrlPressed && urlWithoutParams !== this.homepageRoute) {
			this.offersFacade.setInitialState();
		}
	}

	moveToSubItem(subitem: MenuItem, groupName: string): void {
		let link = subitem.link;

		if (subitem.link.includes('wakacje-autokarem') && subitem.name) {
			const name = normalizeStringToUrl(subitem.name);
			const group = normalizeStringToUrl(groupName);
			link = link.replace('wakacje-autokarem', `wakacje-autokarem/${group}/${name}`);
		}

		const index = link.indexOf('/wakacje-autokarem');

		if (index === -1) {
			window.location.href = link;
			return;
		}

		link = `${window.origin}${link.slice(index)}`;
		window.location.href = link;
	}

	private filterActiveManuItems(items: MenuItem[]): MenuItem[] {
		return items
			.filter((item) => item.isActive)
			.map((item) => ({
				...item,
				childMenus: item.childMenus ? this.filterActiveManuItems(item.childMenus) : [],
			}));
	}
}
