import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ContactFormComponent } from '@/common/contact-form/contact-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@/modules/shared/button/button.module';

@Component({
	selector: 'app-contact-form-dialog',
	standalone: true,
	imports: [ContactFormComponent, ReactiveFormsModule, ButtonModule],
	template: ` @if (formSend()) {
			<div class="text-mainColor h-full justify-center px-16 py-5 flex flex-col items-center">
				<h1 class="text-mainYellow font-extrabold text-2xl pb-5">Dziękujemy</h1>
				<p class="w-80 text-center pb-5">Wiadmość została wysłana pomyślnie!</p>
				<app-button (click)="dialog.close()" type="box-button-added">Zamknij</app-button>
			</div>
		} @else {
			<button
				(keyup.escape)="dialog.close()"
				(click)="dialog.close()"
				class="absolute text-2xl right-4 top-2 text-mainColor"
				aria-label="Zamknij"
			>
				<i class="fas fa-times"></i>
			</button>
			<h1 class="uppercase font-extrabold text-xl text-center text-mainColor pt-6">Formularz kontaktowy</h1>
			<div class="px-3">
				<app-contact-form (formSend)="formSend.set(true)">
					<app-button class="w-fit" type="footer">Wyślij</app-button>
				</app-contact-form>
			</div>
		}`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFormDialogComponent {
	readonly dialog = inject(MatDialogRef<ContactFormComponent>);
	readonly formSend = signal(false);
}
