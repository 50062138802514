import { ChangeDetectionStrategy, Component, DestroyRef, inject, input, OnInit, output, signal } from '@angular/core';
import { AsyncPipe, NgClass, NgOptimizedImage } from '@angular/common';
import { MenuItem } from '@/new-navi/navi.api.service';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GeneralDataFacade } from '@/_store/general/general.facade';

@Component({
	selector: 'app-navi-desktop',
	standalone: true,
	imports: [NgOptimizedImage, NgClass, RouterLink, AsyncPipe],
	templateUrl: './desktop.component.html',
	styleUrl: './desktop.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopComponent implements OnInit {
	readonly emitLogOut = output<void>();
	readonly sideActions = output<MouseEvent>();
	readonly moveToSubItem = output<{ menuItem: MenuItem; groupName: string }>();

	readonly menuItems = input.required<MenuItem[]>();
	readonly homepageRoute = input.required<string>();
	readonly childToDisplay = signal<MenuItem[]>([]);
	readonly groupName = signal<string>(null);
	readonly shouldAddShadow = signal(false);

	readonly isLoggedIn$ = inject(GeneralDataFacade).isLoggedIn$;

	private readonly router = inject(Router);
	private readonly destroyRef = inject(DestroyRef);

	ngOnInit(): void {
		this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.shouldAddShadow.set(/oferta|rezerwacja|login|kontakt/.test(this.router.url));
			}
		});
	}

	triggerMask(action: 'hide' | 'show', children: MenuItem[] = null, groupName: string = null): void {
		this.childToDisplay.set(action === 'show' && children ? children : []);
		this.groupName.set(groupName);
	}

	showContact(): void {
		this.router.navigate(['/kontakt']);
	}
}
