import { CurrentOfferFacade } from '@/_store/current-offer/current-offer.facade';
import { TermDateRange } from '@/_store/current-offer/current-offer.types';
import { GeneralDataFacade } from '@/_store/general/general.facade';
import { Injectable, inject } from '@angular/core';
import { filter, switchMap, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FetchOfferStatistics {
	private readonly currentOfferFacade = inject(CurrentOfferFacade);
	private readonly generalDataFacade = inject(GeneralDataFacade);

	private readonly isLoggedIn$ = this.generalDataFacade.isLoggedIn$;

	getNewStatistics(termId: TermDateRange['termId']): void {
		this.isLoggedIn$
			.pipe(
				take(1),
				filter(Boolean),
				switchMap(() => this.currentOfferFacade.product$.pipe(take(1))),
			)
			.subscribe((product) => {
				this.currentOfferFacade.fetchStatisticsData(termId, product.id);
			});
	}
}
