<div class="flex flex-col md:grid grid-flow-col auto-cols-fr gap-2 w-full px-2 md:px-0">
	@for (item of filters(); track i; let i = $index) {
		@if (isMobile$ | async) {
			<app-top-search-filter
				(click)="openMobileModal(item.id)"
				[elementType]="item.isInput ? TopSearchFilterElType.INPUT : TopSearchFilterElType.BTN"
				[filter]="item"
				[style.--radius]="isDropdownOpenArray[i] ? '16px 16px 0 0' : '16px'"
				class="relative w-full md:mx-4 my-1 md:my-0"
			>
			</app-top-search-filter>
		} @else {
			@if (item.id === TopSearchDropdownItemMode.SEARCH) {
				<div class="relative md:mx-4 my-1 md:my-0">
					<i
						(click)="search()"
						class="absolute top-1/2 -translate-y-1/2 left-3 fa-solid fa-magnifying-glass text-[#dcdcdc] cursor-pointer hover:text-mainColor"
					></i>
					<input
						(keydown.enter)="search()"
						type="text"
						[(ngModel)]="searchValue"
						class="w-full placeholder:font-normal bg-white border-1 border-[#dcdcdc] font-bold rounded-full text-mainColor px-10 py-2 focus:outline-none"
						[placeholder]="item.text"
					/>
				</div>
			} @else {
				<app-top-search-filter
					[filter]="item"
					[style.--radius]="'9999px'"
					[appTriggerDropdown]="dropdown"
					[style.maxWidth.px]="itemMaxWidthPx"
					class="relative md:mx-4 my-1 md:my-0"
				>
					<app-dropdown
						[style.--radius]="'24px'"
						[style.--border-width]="'0 1px 1px 1px'"
						[style.--border-color]="'#dcdcdc'"
						[style.--shadow]="'0 0 12px rgb(0 0 0 / 8%)'"
						(dropdownStateChange)="changeOpenState($event, i)"
						(closeDropdownEvent)="fetchOffers(); changeOpenState(false, i)"
						class="dropdown"
						[centerHorizontally]="true"
						[width]="
							item.id === TopSearchDropdownItemMode.NIGHTS ||
							item.id === TopSearchDropdownItemMode.PARTICIPANTS
								? '300px'
								: item.id === TopSearchDropdownItemMode.DATE_RANGE
									? '500px'
									: '350px'
						"
						dropdown
						[scrollable]="false"
						#dropdown
					>
						<app-top-search-dropdown-item
							[mode]="item.id"
							(valueChange)="valueChange = $event"
							(triggerClose)="closeDropdown()"
						></app-top-search-dropdown-item>
					</app-dropdown>
				</app-top-search-filter>
			}
		}
	}
</div>
