import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import {
	DateRange,
	DefaultMatCalendarRangeStrategy,
	MAT_DATE_RANGE_SELECTION_STRATEGY,
} from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { OfferDateRange } from '@/_store/offers/offers.types';

@Component({
	selector: 'app-top-search-date-picker',
	templateUrl: './top-search-date-picker.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
			useClass: DefaultMatCalendarRangeStrategy,
		},
	],
})
export class TopSearchDatePickerComponent {
	@Input() offersDateRange: DateRange<Date> = new DateRange<Date>(null, null);
	@Input() selectedDateRange: DateRange<Date> = new DateRange<Date>(null, null);
	@Output() selectedDateRangeChange: EventEmitter<OfferDateRange> = new EventEmitter<OfferDateRange>();

	constructor(
		private readonly datePipe: DatePipe,
		private readonly cdr: ChangeDetectorRef,
	) {}

	clearDateRange(): void {
		this.selectedDateRange = new DateRange<Date>(null, null);
		this.dateRangeChange();
		this.cdr.detectChanges();
	}

	onSelectedChange(date: Date): void {
		if (
			this.selectedDateRange &&
			this.selectedDateRange.start &&
			date > this.selectedDateRange.start &&
			!this.selectedDateRange.end
		) {
			this.selectedDateRange = new DateRange(this.selectedDateRange.start, date);
			this.dateRangeChange();
		} else {
			this.selectedDateRange = new DateRange<Date>(date, null);
		}
	}

	private dateRangeChange(): void {
		this.selectedDateRangeChange.emit({
			lower: this.datePipe.transform(this.selectedDateRange.start, 'yyyy-MM-dd'),
			upper: this.datePipe.transform(this.selectedDateRange.end, 'yyyy-MM-dd'),
		});
	}
}
