@if (!loading()) {
	<app-navi></app-navi>
	<app-availability-error></app-availability-error>
}

@if (loading()) {
	<div class="flex h-full flex-col justify-center items-center loader-padding">
		<h1 class="text-mainColor font-bold text-2xl">Ładujemy dla Ciebie oferty</h1>
		<i class="fa-solid fa-sun animate-spin-slow text-accentColor text-3xl p-4"></i>
	</div>
} @else {
	<router-outlet class="bg-[#f0f0f0]"></router-outlet>
}

@if (!loading()) {
	<app-footer></app-footer>
}

<div id="fb-root"></div>
<noscript>
	<iframe [src]="googleTagManagerUrl | trustUrl" height="0" width="0" class="hidden invisible"></iframe>
</noscript>
