<footer class="print:hidden" id="footer" *ngIf="shouldBeDisplayed">
	<app-newsletter></app-newsletter>
	<div class="bg-accentColor text-white flex flex-col justify-center py-5">
		<div class="lg:max-w-[1440px] w-full mx-auto">
			<h3 class="text-2xl text-center md:text-left font-semibold">Skontaktuj się z nami</h3>
			<div class="flex flex-col md:flex-row mx-6 md:mx-0 justify-between">
				<div class="footer-contact-container md:pr-4">
					<p>
						Telefoniczne Centrum Rezerwacji<br />
						{{ OPENING_HOURS.days }}
					</p>
					<a class="w-full" [href]="'tel:' + OPENING_HOURS.tel">{{ OPENING_HOURS.tel }} </a>
				</div>
				<div class="footer-contact-container">
					<p>
						@for (point of SALES_POINTS | keyvalue; track point.value) {
							{{ point.value }}
							@if (!$last) {
								<br />
							}
						}
					</p>
					<a target="_blank" href="https://goo.gl/maps/e26cVnpXr9AK9ZxC6">Znajdź na mapie</a>
				</div>
				<div class="footer-contact-container md:pl-4">
					<p>Formularz kontaktowy</p>
					<button (click)="openContactFormDialog()">Napisz do nas</button>
				</div>
			</div>
		</div>
	</div>
	<div class="lg:max-w-[1440px] w-full mx-auto">
		<div class="flex flex-col mx-6 md:mx-0 md:flex-row justify-between text-mainColor py-5">
			<div class="py-2 md:py-0" *ngFor="let col of sections">
				<h3 class="text-base font-semibold">{{ col.name }}</h3>
				<ul *ngFor="let item of col.footerItem">
					<li>
						<a class="hover:underline" target="_blank" [href]="item.link">{{ item.name }}</a>
					</li>
				</ul>
			</div>
			<div class="text-center md:text-right">
				<h3 class="text-base font-semibold">OSKAR w Internecie</h3>
				<a
					target="_blank"
					href="https://www.facebook.com/BiuroPodrozyOSKAR"
					aria-label="Odwiedź nas na Facebook"
					><i class="fab text-3xl mr-2 fa-facebook"></i
				></a>
				<a
					target="_blank"
					href="https://www.instagram.com/oskar_biuropodrozy/"
					aria-label="Odwiedź nas na Instagramie"
					><i class="fab text-3xl fa-instagram"></i
				></a>
				<a target="_blank" href="https://www.youtube.com/@ctoskar" aria-label="Odwiedź nas na Youtube"
					><i class="fab text-3xl ml-2 fa-youtube"></i
				></a>
			</div>
		</div>
	</div>
</footer>
