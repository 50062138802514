import { GeneralDataFacade } from '@/_store/general/general.facade';
import { OffersFacade } from '@/_store/offers/offers.facade';
import { BaseFilter, OfferDateRange, Selected } from '@/_store/offers/offers.types';
import { createQueryParams } from '@/_store/offers/offers.utils';
import { replacePolishLetters } from '@/_utils/strings/replace-polish-letters';
import {
	CheckboxFilterValue,
	MultiChoiceComponent,
} from '@/new-top-search/top-search-dropdown-item/multi-choice/multi-choice.component';
import { TopSearchDatePickerComponent } from '@/new-top-search/top-search-dropdown-item/top-search-date-picker/top-search-date-picker.component';
import {
	CounteringModes,
	DropdownType,
	MultiChoiceModes,
	Participant,
	Row,
	RowValue,
	TopSearchDropdownItemMode,
	TopSearchFilterElType,
} from '@/new-top-search/top-search-dropdown-item/top-search-dropdown-item.types';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	signal,
	ViewChild,
} from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, map, Observable, of, Subject, take, takeUntil } from 'rxjs';

@Component({
	selector: 'app-top-search-dropdown-item',
	templateUrl: './top-search-dropdown-item.component.html',
	styleUrls: ['./top-search-dropdown-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopSearchDropdownItemComponent implements OnInit, OnDestroy {
	@Input() mode!: TopSearchDropdownItemMode;

	@Output() valueChange: EventEmitter<boolean> = new EventEmitter();

	@Output() triggerClose: EventEmitter<void> = new EventEmitter();

	@ViewChild(TopSearchDatePickerComponent, { static: false })
	topSearchDatePickerComponent: TopSearchDatePickerComponent;

	@ViewChild(MultiChoiceComponent, { static: false })
	multiChoiceComponent: MultiChoiceComponent;

	isMobile$ = this.generalFacade.isMobile$;
	type!: DropdownType;
	DropdownType = DropdownType;
	offersDateRange$: Observable<DateRange<Date>> = this.offersFacade.offersDateRange$;
	selectedDateRange$: Observable<DateRange<Date>> = this.offersFacade.selectedDateRange$;
	rows$: Observable<Row[]> = of([]);
	counters$: Observable<{ [key: number]: number }> = of({});
	searchingValue$: BehaviorSubject<string> = new BehaviorSubject<string>('');
	ownTransport$: Observable<boolean> = this.offersFacade.ownTransport$;
	ownTransport = false;
	searchBarDropdownOpened = signal(false);
	selected$ = this.offersFacade.selected$.pipe(
		map((selected: Selected) => selected[this.mode as keyof Selected] as number[]),
	);
	options$: Observable<BaseFilter[]> = combineLatest([
		this.searchingValue$,
		this.offersFacade.getOptions(this.mode),
	]).pipe(
		map(([searching, options]) =>
			options.filter((obj) =>
				replacePolishLetters(obj.name.toLowerCase()).includes(replacePolishLetters(searching.toLowerCase())),
			),
		),
	);

	readonly TopSearchDropdownItemMode = TopSearchDropdownItemMode;
	readonly TopSearchFilterElType = TopSearchFilterElType;

	private readonly destroy$: Subject<void> = new Subject();

	constructor(
		private readonly offersFacade: OffersFacade,
		private readonly generalFacade: GeneralDataFacade,
		private readonly router: Router,
	) {}

	ngOnInit(): void {
		this.options$ = this.setMultiChoiceOptions();
		this.setCounterValues();
		this.setDropdownType();
		if (this.mode === TopSearchDropdownItemMode.STOPS) {
			this.ownTransport$
				.pipe(takeUntil(this.destroy$))
				.subscribe((ownTransport) => (this.ownTransport = ownTransport));
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	filtersActions(filter: CheckboxFilterValue | OfferDateRange | RowValue): void {
		filter = filter as CheckboxFilterValue;
		this.valueChange?.emit(true);
		switch (this.mode) {
			case TopSearchDropdownItemMode.LOCATIONS:
				if (filter.value) this.offersFacade.addLocationsFilterOption(filter.id);
				else this.offersFacade.removeLocationsFilterOption(filter.id);
				break;
			case TopSearchDropdownItemMode.KINDS:
				if (filter.value) this.offersFacade.addKindsFilterOption(filter.id);
				else this.offersFacade.removeKindsFilterOption(filter.id);
				break;
			case TopSearchDropdownItemMode.BOARDS:
				if (filter.value) this.offersFacade.addBoardsFilterOption(filter.id);
				else this.offersFacade.removeBoardsFilterOption(filter.id);
				break;
			case TopSearchDropdownItemMode.TAGS:
				if (filter.value) this.offersFacade.addTagsFilterOption(filter.id);
				else this.offersFacade.removeTagsFilterOption(filter.id);
				break;
			case TopSearchDropdownItemMode.CONVENIENCES:
				if (filter.value) this.offersFacade.addConveniencesFilterOption(filter.id);
				else this.offersFacade.removeConveniencesFilterOption(filter.id);
				break;
			case TopSearchDropdownItemMode.STOPS:
				if (filter.value) this.offersFacade.addStopsFilterOption(filter.id);
				else this.offersFacade.removeStopsFilterOption(filter.id);
				break;
			case TopSearchDropdownItemMode.DATE_RANGE:
				filter = filter as unknown as OfferDateRange;
				this.offersFacade.updateOffersDateRange(filter);
				break;
			case TopSearchDropdownItemMode.PARTICIPANTS:
				filter = filter as unknown as RowValue;
				if (filter.type === Participant.ADULTS) {
					this.offersFacade.updateAdultsCount(filter.value);
				}
				if (filter.type === Participant.CHILDREN) {
					this.offersFacade.updateChildrenCount(filter.value);
				}
				break;
			case TopSearchDropdownItemMode.NIGHTS:
				filter = filter as unknown as RowValue;
				this.offersFacade.updateNights(filter);
				this.offersFacade.updateNightsOptions(filter);
				break;
			case TopSearchDropdownItemMode.SEARCH:
				break;
			default:
				const value: never = this.mode;
				throw new Error(`There is no option like ${value}`);
		}
	}

	clearFilterValue(): void {
		switch (this.type) {
			case DropdownType.COUNTER:
				if (this.mode === TopSearchDropdownItemMode.NIGHTS) {
					this.offersFacade.setDefaultNights();
				}
				if (this.mode === TopSearchDropdownItemMode.PARTICIPANTS) {
					this.offersFacade.setDefaultAdultValue();
					this.offersFacade.setDefaultChildrenValue();
				}
				break;
			case DropdownType.MULTI:
				this.multiChoiceComponent.toggleAll(false);
				break;
			case DropdownType.DATE:
				this.topSearchDatePickerComponent.clearDateRange();
				break;
			case DropdownType.SEARCH:
				this.offersFacade.clearLocationsFilter();
				break;
			default:
				const value: never = this.type;
				throw new Error(`There is no type like ${value}`);
		}
	}

	updateOwnTransport(ownTransport: boolean): void {
		this.offersFacade.updateOwnTransport(ownTransport);
		this.valueChange?.emit(true);
	}

	closeDropdown(alreadyNavigated = false): void {
		this.valueChange?.emit(true);
		this.triggerClose.emit();
		if (
			!window.location.href.includes('wakacje-autokarem') ||
			(this.mode === TopSearchDropdownItemMode.SEARCH && !alreadyNavigated)
		) {
			this.offersFacade.urlParams$.pipe(take(1)).subscribe((params) => {
				const queryParams = createQueryParams(params);
				this.router.navigate(['/wakacje-autokarem'], {
					queryParams,
				});
			});
		}
	}

	private setMultiChoiceOptions(): Observable<BaseFilter[]> {
		return combineLatest([this.searchingValue$, this.offersFacade.getOptions(this.mode)]).pipe(
			map(([searching, options]) =>
				options.filter((obj) =>
					replacePolishLetters(obj.name.toLowerCase()).includes(
						replacePolishLetters(searching.toLowerCase()),
					),
				),
			),
		);
	}

	private setCounterValues(): void {
		if (this.mode === TopSearchDropdownItemMode.NIGHTS) {
			this.rows$ = this.offersFacade.nightsRows$;
			this.counters$ = this.offersFacade.nightCounters$;
		} else {
			this.rows$ = this.offersFacade.participantsRows$;
			this.counters$ = this.offersFacade.participantCounters$;
		}
	}

	private setDropdownType(): void {
		switch (true) {
			case MultiChoiceModes.includes(this.mode):
				this.type = DropdownType.MULTI;
				break;
			case CounteringModes.includes(this.mode):
				this.type = DropdownType.COUNTER;
				break;
			case this.mode === TopSearchDropdownItemMode.DATE_RANGE:
				this.type = DropdownType.DATE;
				break;
			case this.mode === TopSearchDropdownItemMode.SEARCH:
				this.type = DropdownType.SEARCH;
				break;
			default:
				const value: never = this.mode as unknown as never;
				throw new Error(`There is no option like ${value}`);
		}
	}
}
